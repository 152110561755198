// Generating a 32 random chars long string
export const generateRandomString = (): string => {
  return Array(32).fill(null).map(() => (Math.round(Math.random() * 16)).toString(16)).join('');
};

export const numberFormatter = (value: number): string => value ? value.toFixed(2) : '';

export const toArrayBuffer = (value: string): ArrayBuffer => {
  const buf = new ArrayBuffer(value.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== value.length; ++i) {
    view[i] = value.charCodeAt(i) & 0xFF;
  }

  return buf;
};
